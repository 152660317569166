function Footer()
{
    return(   <section className="contact">
    <div className="container-fluid">
        <div className="row">
            <div className="col-2">
                <p id="contact">contact</p>
            </div>
            <div className="col-10">

                <p id="get_in">GET IN TOUCH</p>
                <hr/>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-4 detail">
                            <p>Address</p>
                            <p>School of Mobile Computing and Communication,<br/>Jadavpur University Salt Lake Campus <br/> West Bengal, India -</p>

                        </div>
                        <div className="col-md-4 detail">
                            <p>Phone number</p>
                            <p>+91-xxxxxxxxxx</p>
                         

                        </div>
                        <div className="col-md-4 detail">
                            <p>Email ID</p>
                            <p>example@gmail.com</p>

                        </div>
                    </div>

                </div>

                <div className="col-md-8 cfl">

                </div>
                <div className="col-md-4 social">

                </div>
                <div className="col-12">
                    <div className="copyright">
                    <hr/>
                    <p>Copyright © {new Date().getFullYear()} DMC Alumni Association  | All Rights Reserved<br/>

<br/></p>
                    </div>
                  
                </div>
            </div>
        </div>

    </div>
</section>)
}

export default Footer