import logo from './logo.svg';
import './App.css';
import PrivateRoute from './Privateroute';
import Main from './Main/main'
import Loader from './Loader/Loader'
import Header from './Main/header'
import Footer from './Main/footer'

import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import {TransitionGroup, CSSTransition} from 'react-transition-group'
import React from 'react';
const Mainevent = React.lazy(() => {return new Promise(resolve => {
    setTimeout(() => resolve(import("./Main/Mainevent")), 1000);
  });});
  const Mainhome = React.lazy(() => {return new Promise(resolve => {
    setTimeout(() => resolve(import("./Main/Mainhome")), 1000);
  });});
  const Signup = React.lazy(() => {return new Promise(resolve => {
    setTimeout(() => resolve(import("./Account/Signup")), 1000);
  });});
  const Signin = React.lazy(() => {return new Promise(resolve => {
    setTimeout(() => resolve(import("./Account/Signin")), 1000);
  });});
  const DMCAdmin = React.lazy(() => {return new Promise(resolve => {
    setTimeout(() => resolve(import("./Account/DMCAdmin")), 1000);
  });});
  const Admin_Dashboard = React.lazy(() => {return new Promise(resolve => {
    setTimeout(() => resolve(import("./Admin_Dashboard/Admin_Dashboard")), 1000);
  });});
  const Dashboard = React.lazy(() => {return new Promise(resolve => {
    setTimeout(() => resolve(import("./Dashboard/dashboard")), 1000);
  });});

function App() {

    return ( <> 
    <Header/>
 
        <Router>
     <Routes>
         <Route exact path='/'   element={
              <React.Suspense fallback={<Loader/>}>
                <Mainhome />
              </React.Suspense>
            }/>
             <Route exact path='/Event'   element={
              <React.Suspense fallback={<Loader/>}>
                <Mainevent />
              </React.Suspense>
            }/>
        
          <Route exact path='/Dashboard'   element={
              <React.Suspense fallback={<Loader/>}>
                <Dashboard />
              </React.Suspense>
            }/>
            <Route exact path='/DMCAlumni/AdminDashboard'   element={
              <React.Suspense fallback={<Loader/>}>
                <Admin_Dashboard />
              </React.Suspense>
            }/>
            <Route exact path='/Signup'   element={
              <React.Suspense fallback={<Loader/>}>
                <Signup />
              </React.Suspense>
            }/>
           <Route exact path='/Signin'   element={
              <React.Suspense fallback={<Loader/>}>
                <Signin />
              </React.Suspense>
            }/>
             <Route exact path='/DMCAlumni/Admin'   element={
              <React.Suspense fallback={<Loader/>}>
                <DMCAdmin />
              </React.Suspense>
            }/>

       
     </Routes>

 

 </Router>
      
 </>);
}

export default App;