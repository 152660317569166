import React from 'react';
import './header.css'
function Header()
{
    React.useEffect(() => {
        document.querySelector('.navbar-brand').innerHTML =""
        document.querySelector('.navbar-brand').innerHTML = '<img src="/image/alumni_logo_black@2x.png" />'
    })
  
    const logoutuser = e => {
        localStorage.removeItem('dmcalumiassociationuser')
        window.location.href='/Signin'
    }
    const adminlogoutuser = e=>{
        localStorage.removeItem('dmcalumiassociationadminuser')
        window.location.href='/DMCAlumni/Admin'
    }
    return(  
        <>
           {/*<div className="topheader">
                <p></p>
                <p>
                    <span className="material-icons">
                        email
                    </span>example@email.com</p>
                <p>
                    <span className="material-icons">
                        call
                    </span>+91-xxxxxxxxx</p>
                <p id="">{new Date().toLocaleString("en-US", {day: '2-digit'})} {new Date().toLocaleString("en-US", {month: "long"})}
                    , {new Date().getFullYear()}</p>
    </div>*/}
        
        <nav className="navbar navbar-expand-sm bg-dark navbar-dark sticky-top" id="navbar1">
    <a
        className="navbar-brand"
        href="/"
        style={{
        color: 'black'
    }}></a>

    <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#collapsibleNavbar">
        <span className="navbar-toggler-icon"></span>
    </button>

    <div className="collapse navbar-collapse " id="collapsibleNavbar">
        <ul className="navbar-nav ml-auto">
            <li>
            <a href="/">Home
                </a></li>
            <li>About</li>
            <li>
            <a href="/Event">Event
                </a></li>
            <li>Gallery</li>
            <li>Contact</li>
            {!localStorage.getItem('dmcalumiassociationadminuser') ?
            <li>
                <a href="/DMCAlumni/Admin">Admin
                </a>
            </li> : <li>
            <a className="dropdown">
                <span className="material-icons" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            admin_panel_settings
                        </span>

  <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
 
    <a className="dropdown-item" href="/DMCAlumni/AdminDashboard"> <span className="material-icons">
                                    dashboard
                                </span>Admin Dashboard</a>
    <a className="dropdown-item" href="#"><span className="material-icons">
                                    settings
                                </span>Setting</a>
    <a className="dropdown-item" onClick={e=>adminlogoutuser()}><span className="material-icons" >
                                    logout
                                </span>
                                Logout</a>
  </div>
</a>
            </li> }
            {localStorage.getItem('dmcalumiassociationuser') ?
            <li>
                
                <a className="dropdown">
                <span className="material-icons " id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            account_circle
                        </span>

  <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
 
    <a className="dropdown-item" href="/Dashboard"> <span className="material-icons">
                                    dashboard
                                </span>Dashboard</a>
    <a className="dropdown-item" href="#"><span className="material-icons">
                                    settings
                                </span>Setting</a>
    <a className="dropdown-item" onClick={e=>logoutuser()}><span className="material-icons" >
                                    logout
                                </span>
                                Logout</a>
  </div>
</a>
                 { /*<a id="userdropdown">
                <div >

                    <div className="dropdown">

                        <span className="material-icons dropbtn" id="dropexp">
                            account_circle
                        </span>

                        <div className="dropdown-content">
                        <p className="dropdown-contentli">
                                <span className="material-icons">
                                    settings
                                </span>Dashboard</p>
                        <p className="dropdown-contentli">
                                <span className="material-icons">
                                    settings
                                </span>Setting</p>
                            <hr/>
                            <p className="dropdown-contentli">
                                <span className="material-icons">
                                    logout
                                </span>
                                Logout</p>
                        </div>
                    </div>

                </div>
            </a>*/}</li>:<>
        <li id="join">
                <a href="/Signin">Login
                </a>
            </li>
            <li id="join">
                <a href="/Signup">Signup
                </a>
            </li></>}
          
        </ul>
    </div>
</nav> </>)
}
export default Header