import React, {useEffect, useState} from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import axios from 'axios'
import Footer from './footer'
let hostname = window.location.hostname;
var month = {
    "01": 'January',
    "02": 'February',
    "03": 'March',
    "04": 'April',
    "05": 'May',
    "06": 'June',
    "07": 'July',
    "08": 'August',
    "09": 'September',
    "10": 'October',
    "11": 'November',
    "12": 'December'
}
const customStyles = {
    content: {
        border: '0',
        borderRadius: '4px',
        bottom: 'auto',
        minHeight: '10rem',
        maxHeight: window.innerWidth < 800
            ? '40em'
            : '100em',
        left: '50%',
        padding: '2rem',
        position: 'fixed',
        right: 'auto',
        top: '50%',
        transform: 'translate(-50%,-50%)',
        minWidth: '20rem',
        width: window.innerWidth < 800
            ? '100%'
            : '60%',
        maxWidth: '50rem'
    },
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.8)'
    }
};
var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    centerMode: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true
};
function Mainhome(){
    const [emailval,
        setemailval] = React.useState('')
    const [passwordval,
        setpasswordval] = React.useState('')
    const [modalIsOpen,
        setIsOpen] = React.useState(false);
    const [nextsept,
        setnextstep] = React.useState(false);

    const alreadyexist = e => {
        //console.log(e)
        setnextstep(false)
    }
    function openModal() {
        setIsOpen(true);

    }
    React.useEffect(() => {
        showrecentevent()
        shownoticelist()
       // document.getElementById('navbar1').style.background="green" 
       //document.title = "DMC Slumni"
    }, [])
    function afterOpenModal() {
        // references are now sync'd and can be accessed. subtitle.style.color = '#f00';
    }

    function closeModal() {
        setIsOpen(false);
        setnextstep(false)
    }
    const {useState, useEffect} = React;
    const [countdownDate,
        setCountdownDate] = useState();
    console.log()
    const [state,
        setState] = useState({days: 0, hours: 0, minutes: 0, seconds: 0});

    useEffect(() => {}, []);

    const updateCountdown = () => {

        const currentTime = new Date().getTime();
        const distanceToDate = countdownDate - currentTime;
        // console.log(((new Date().toLocaleString() + '').split(',')[0])-'1/7/2022') if
        // (((new Date().toLocaleString() + '').split(',')[0]) != '1/9/2022') {
        // Calculate days, hours, minutes and seconds remaining
        let days = Math.floor(distanceToDate / (1000 * 60 * 60 * 24));
        let hours = Math.floor((distanceToDate % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),);
        let minutes = Math.floor((distanceToDate % (1000 * 60 * 60)) / (1000 * 60),);
        let seconds = Math.floor((distanceToDate % (1000 * 60)) / 1000);

        // For visual appeal, add a zero to each number that's only one digit
        const numbersToAddZeroTo = [
            1,
            2,
            3,
            4,
            5,
            6,
            7,
            8,
            9
        ];

        if (numbersToAddZeroTo.includes(hours)) {
            hours = `0${hours}`;
        } else if (numbersToAddZeroTo.includes(minutes)) {
            minutes = `0${minutes}`;
        } else if (numbersToAddZeroTo.includes(seconds)) {
            seconds = `0${seconds}`;
        }

        // Set the state to each new time
        if (seconds > 0) 
            setState({days: days, hours: hours, minutes, seconds});
            // }
        }
    const [upcomingevent,
        setupcomingevent] = React.useState([])
    const [noevent,
        setnoevent] = useState(true)
    const showrecentevent =  e => {
        //  setscanning(true)
        axios
            .get(`${process.env.REACT_APP_CONN}://${hostname}/dmc/user_php/Show_Recent_Event.php`)
            .then(res => {
                //  console.log(res.data)
                var d1 = res
                    .data[0]
                    .STARTDATE
                    .split('-')
                var temp = d1[0]
                d1[0] = d1[2]
                d1[2] = temp
                temp = d1[0]
                d1[0] = d1[1]
                d1[1] = temp
                d1 = d1.join('-')
                setCountdownDate(new Date(d1).getTime())
                setupcomingevent({
                    ...res.data[0]
                })
                setTimeout(() => {
                    document.getElementById('loading_event').style.display="none"
                    document.getElementById('mainevent_start').style.display="block"
                   
                }, 200);
            })
            .catch(function (error) {
                setnoevent(false)
                setTimeout(() => {
                    document.getElementById('loading_event').style.display="none"
                    document.getElementById('mainevent_start').style.display="block"
                   
                }, 200);
            })
    }
    if (countdownDate) 
        setInterval(() => updateCountdown(), 1000);
   
   /* const {linkedInLogin} = useLinkedIn({
        clientId: '', redirectUri: `${window.location.origin}/linkedin`, // for Next.js, you can use `${typeof window === 'object' && window.location.origin}/linkedin`
        onSuccess: (code) => {
            console.log(code);
        },
        onError: (error) => {
            console.log(error);
        }
    });*/
    const [noticelist,
        setnoticelist] = React.useState([])
    const shownoticelist = async e => {
        //  setscanning(true)

        const res = await axios(`${process.env.REACT_APP_CONN}://${hostname}/dmc/admin_php/Show_Notice.php`);

        setnoticelist({
            ...res.data.sent
        })

    }
    return(<>
     <div id="smmccalumnihome" className="animate__animated animate__fadeIn">
   
     <Slider
                dots={false}
                slidesToShow={1}
                slidesToScroll={1}
                autoplay={true}
                arrows={true}
                autoplaySpeed={3000}>
                 
                      <div className="wdt">
                    <img
                        className="img"
                        src={'/image/WhatsApp Image 2021-12-17 at 10.18.20 PM (1).jpeg'}
                     />
                    <div className="wdt_h3">
                        <center>
                            <p>Welcome Home</p>
                            <h3>DMC Alumni Association</h3>
                            <a href="/Signup"><button className="btn btn-default">Join our network</button></a>
                        </center>
                    </div>

                </div>

              
            </Slider>

            <div className="news_login">
                <div className="news_login_container">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-5">
                                <div className="upcoming_section news_login_setion">
                                    <div className="news_login_setion_header">
                                        <h1>Upcoming Events</h1>
                                    </div>
                                    <div className="new_login_content">
                                       <span id="loading_event">
                                                    <div className="col-md-12">
                                                        
                                                        <img src="/image/loading.gif" />
                                                    </div>
                                        </span>
                                        <span id="mainevent_start" className="animate__animated animate__fadeIn">
                                        <div className="container-fluid">
                                            <div className="row">   
                                                {noevent
                                                    ? <> <div className="col-md-3 col-12">
                                                        {upcomingevent.STARTDATE
                                                            ? <> <div className="event_date">
                                                                <span>{month[
                                                                        upcomingevent
                                                                            .STARTDATE
                                                                            .split('-')[1]
                                                                    ]}</span><br/>
                                                                <span>
                                                                    {upcomingevent
                                                                        .STARTDATE
                                                                        .split('-')[2]}</span>
                                                            </div> < span className = "event_year_p" > {
                                                                upcomingevent
                                                                    .STARTDATE
                                                                    .split('-')[0]
                                                            } </span>
                                                 </ >
                                                            : <></>}

                                                    </div> < div className = "col-md-9 col-12" > <p>{upcomingevent.TITLE}</p> <h3> <span className="material-icons">
                                                        location_on
                                                    </span>
                                                {
                                                    upcomingevent.LOCATION
                                                } </h3>
                                                    <h3><span className="material-icons">
                                                            timer 
                                                    </span > {
                                                    upcomingevent.STARTTIME
                                                } </h3>
                                                    <div className='countdown-wrapper'>
                                                        <span>Time remianing..</span > <div className='time-section'>
                                                    <div className='time'>{state.days || '0'}<br/>
                                                        <small className="time-text">Days</small>
                                                    </div>

                                                    <div className='time'>{state.hours || '00'}<br/>
                                                        <small className="time-text">Hours</small>
                                                    </div>

                                                    <div className='time'>{state.minutes || '00'}<br/>
                                                        <small className="time-text">Minutes</small>
                                                    </div>
                                                    <div className='time'>{state.seconds || '00'}<br/>
                                                        <small className="time-text">Seconds</small>
                                                    </div>

                                                </div> </div>

                                                </div > </> : <div className="noevent text-center">
                                                    <span className="material-icons">event_busy</span>
                                                    <p>There are no upcoming events currently.<br/>
                                                        Kindly contact admin if you would like to list an upcoming event.</p>
                                                </div>}
                                            </div>
                                        </div>
                                        </span>

                                    </div>
                                    <a href="/Event"><div className='news_more '>
                                       <h4>More Event</h4>
                                    </div></a> 
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="news_login_setion">
                                    <div className="news_login_setion_header">
                                        <h1>Notice</h1>
                                    </div>
                                    <div className="notice_section">
                                      
                                                {Object
                                                    .values(noticelist)
                                                    .length === 0
                                                    ? 
                                                            <p
                                                                style={{
                                                                textAlign: 'center',
                                                                fontWeight: 'bold'
                                                            }}>No notice</p>
                                                    
                                                    : Object
                                                        .values(noticelist)
                                                        .length > 4
                                                        ? <marquee direction="up" id="marquee"
                                                         onMouseOver={e=>{document.getElementById('marquee').stop()}}
                                                         onMouseOut={e=>{document.getElementById('marquee').start()}}>
                                                                {Object.values(noticelist) && Object
                                                                    .values(noticelist)
                                                                    .map((data, index) => <Shownoticelist prop={data} serial={index} key={data.ID}/>)}
                                                            </marquee>
                                                        : Object.values(noticelist) && Object
                                                            .values(noticelist)
                                                            .map((data, index) => <Shownoticelist prop={data} serial={index} key={data.ID}/>)}
                                         
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="login_section news_login_setion">
                                    <div className="news_login_setion_header">
                                        <h1>Login or Register</h1>
                                    </div>
                                    <div className="login_button">
                                        <center>
                                            <span className="material-icons">
                                                badge
                                            </span>
                                            <p>
                                                Certain sections of the website are for alumni of DMC.<br/>
                                                Kindly sign in for extended access.</p>
                                                {!localStorage.getItem('dmcalumiassociationuser') ? 
                                            <a href="/Signin"><button className="btn btn-default btn-login-signup">Login</button></a>
                                            :  <a href="/Dashboard"><button className="btn btn-default btn-login-signup">Dashboard</button></a>}
                                        </center>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div className="mission">
                <img
                    src="./image/pexels-element-digital-1370296-min.jpg"
                    className="mission_image"></img>
                <div className="mission_content">
                    <p>Our Mission</p>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and scrambled it to make a type
                        specimen book. It has survived not only five centuries, but also the leap into
                        electronic typesetting, remaining essentially unchanged. It was popularised in
                        the 1960s with the release of Letraset sheets containing Lorem Ipsum passages,
                        and more recently with desktop publishing software like Aldus PageMaker
                        including versions of Lorem Ipsum.</p>
                </div>
            </div>
            <div className=" message">
                <img
                    src="./image/pexels-element-digital-1370296-min.jpg"
                    className=" message_image"></img>
                <div className=" message_content">
                    <p>Message from Director</p>
                    <p>Dr. Arpita Sarkar
                    </p>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and scrambled it to make a type
                        specimen book. \</p>
                </div>
            </div>
            <div className="social_media_post">
                <div className="continer-fluid">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="socialpost linkedin_post"></div>
                        </div>
                        <div className="col-lg-6">
                            <div className="socialpost facebook_post">
                                {/* <iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FTest-page-116887330897443&tabs=timeline&width=840&height=587&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId" width="100%" height="100%" style={{border:'none',overflow:"hidden" }} scrolling="no" frameBorder="0" allowFullScreen={true} allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <br/><br/>
            <br/><br/>
            <br/><br/>
            <Footer/>
     </div>
    </>)
}
function Shownoticelist(props) {
    //  console.log(props)
    return ( <>
        <h3>{props.prop.NOTICE}<br/>
            <span>{props.prop.DATE}</span>
        </h3>
    <hr/> 
           </>)
}
export default Mainhome
