import React, { lazy, Suspense,} from "react";
import './main.css'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Header from './header'
import Footer from './footer'
import Mainhome from './Mainhome'
import GoogleLogin from 'react-google-login';
import GoogleButton from 'react-google-button'
import FacebookLogin from 'react-facebook-login';
import {linkedin, useLinkedIn} from 'react-linkedin-login-oauth2';
// You can use provided image shipped by this package or using your own
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import axios from 'axios'
import Modal from 'react-modal';
//var Service = lazy(() => import ('./Mainhome'));
function Main(e) {
  var Service = lazy(() => import ('./'+e.props));
    return (

        <div id="smmccalumnihome">

            <Routes>   
               
            </Routes>
      
            <Suspense fallback={< div > Content is Loading ...</div>}>
                          <Service/>
            </Suspense>
         

        </div>
    )

}

export default Main;